<script lang="ts" setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["closeModal"]);
const closeModal = () => {
  emits("closeModal");
};
const { redirect } = useBooking();
const shareAction = (share: any) => {
  const currentLink = window.location.href;
  switch (share.id) {
    case 1:
      const tempInput = document.createElement("input");
      tempInput.value = currentLink;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      closeModal();
      break;
    case 2:
      var url =
        "mailto:?subject=Check%20out%20this%20hotel&body=Here%20is%20the%20link%20to%20the%20hotel:%20" +
        currentLink;
      redirect(url);
      break;
    case 3:
      var url = "https://telegram.me/share/url?url=" + currentLink;
      redirect(url);
      break;
    case 4:
      var url = "https://web.whatsapp.com/send?text=" + currentLink;
      redirect(url);
      break;
    case 5:
      var url = "https://www.facebook.com/sharer.php?u=" + encodeURIComponent(currentLink);
      redirect(url);
      break;
    case 6:
      var url = "https://x.com/share?url=" + currentLink;
      redirect(url);
      break;
  }
};

const shares = ref([
  {
    icon: "solar:copy-broken",
    text: "Copy link",
    id: 1,
  },
  {
    icon: "system-uicons:mail",
    text: "Email",
    id: 2,
  },
  {
    icon: "hugeicons:telegram",
    text: "Telegram",
    id: 3,
  },
  {
    icon: "mdi:whatsapp",
    text: "Whatsapp",
    id: 4,
  },
  {
    icon: "mingcute:facebook-line",
    text: "Facebook",
    id: 5,
  },
  {
    icon: "hugeicons:new-twitter-ellipse",
    text: "X Twitter",
    id: 6,
  },
]);
</script>
<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-20" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 translate-y-5"
            enter-to="opacity-100 translate-y-0"
            leave="duration-200 ease-in"
            leave-from="opacity-100 translate-y-0"
            leave-to="opacity-0 translate-y-5"
          >
            <DialogPanel
              class="relative mx-auto min-h-0 overflow-hidden rounded-xl bg-white dark:bg-neutral-900 shadow-2xl transform-gpu opacity-100"
            >
              <DialogTitle as="div" class="py-3 px-4 relative md:py-4">
                <h3
                  class="text-base text-left font-semibold text-neutral-900 lg:text-xl dark:text-neutral-200 ml-4"
                >
                  {{ $t("share") }}
                </h3>
                <button
                  @click="closeModal"
                  class="w-8 h-8 flex items-center justify-center rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700 absolute right-2 top-1/2 transform -translate-y-1/2 focus:outline-none"
                  tabindex="0"
                >
                  <span class="sr-only">{{ $t("close") }}</span>
                  <IconX class="w-5 h-5" />
                </button>
              </DialogTitle>
              <div class="w-screen max-w-3xl px-4 pb-4">
                <div
                  class="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3"
                >
                  <button
                    v-for="share in shares"
                    type="button"
                    @click="shareAction(share)"
                    class="flex w-full items-start rounded-xl p-3 text-left text-neutral-900 dark:bg-white hover:bg-neutral-100 focus:outline-none focus:ring-2 focus:ring-secondary-600"
                  >
                    <Icon :name="share.icon" class="shrink-0" size="24"> </Icon>
                    <span class="text-sm ml-3 grow font-semibold">{{
                      share.text
                    }}</span>
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
